import _ from 'lodash';
import 'jquery-colorbox';
import Hammer from 'hammerjs';
import '!style-loader!css-loader!video.js/dist/video-js.css';
import { COLORBOX } from './application/constants';
import Index from './application/index';
import Pages from './application/pages';
import Dojos from './application/dojos';
import Blocks from './application/blocks';
import Galleries from './application/galleries';
import Users from './application/users';
import News from './application/news';
import Announcements from './application/announcements';
import Administer from './application/administer';

export default class Kyokushin {
  constructor() {
    this.index = new Index();
    this.pages = new Pages();
    this.dojos = new Dojos();
    this.blocks = new Blocks();
    this.galleries = new Galleries();
    this.users = new Users();
    this.news = new News();
    this.announcements = new Announcements();
    this.administer = new Administer();
    this.bootstrapColorbox();
    this.initBackToTop();
    this.initTopMenuResponsiveness();
  }

  /**
   * Bootstraps all elements on the page with class
   */
  bootstrapColorbox() {
    $(`.${COLORBOX.ITEM_CLASS}`).colorbox({
      title: false,
      initialHeight: 500,
      initialWidth: 550,
      closeButton: true,
      close: '&#10005'
    });
    $(document).on('click', `.${COLORBOX.CLOSE_CLASS}`, e => {
      e.preventDefault();
      $.colorbox.close();
    });
  }

  /**
   * Provides functionality for user to scroll back to top once appeared at the bottom.
   */
  initBackToTop() {
    const containerElement = $('#middleContainer');
    const footerElement = $('#footer');
    const backToTopElement = $('.backToTop');
    // There is no such element on home page
    if (backToTopElement.length) {
      // Back To Top Element Initial ...
      const bTTEInitialBottomPosition = +backToTopElement.css('bottom').replace('px', '');
      const backToTop = () => {
        const scrollTop = $(window).scrollTop();
        // Point from where to start showing element
        if (scrollTop > containerElement.offset().top) {
          // Point from where to stick element to container (we don't want it to overlap with footer)
          if (scrollTop + window.innerHeight > footerElement.offset().top) {
            backToTopElement.css('bottom', (bTTEInitialBottomPosition + (scrollTop + window.innerHeight - footerElement.offset().top)));
          } else {
            backToTopElement.css('bottom', bTTEInitialBottomPosition);
          }
          backToTopElement.show();
        } else {
          backToTopElement.hide();
        }
      };
      backToTop();
      $(window).on('scroll resize', _.throttle(backToTop, 100));
      backToTopElement.on('click', e => {
        e.preventDefault();
        $('html,body').animate({
          scrollTop: 0
        }, 700);
      });
    }
  }

  initTopMenuResponsiveness() {
    const topMenuList = $('#topMenuList');
    const topMenuDropdown = $('.topMenu__dropdown');
    if (topMenuDropdown.is(':visible')) {
      const topMenuDropdownHammer = new Hammer(topMenuDropdown.get(0));
      const topMenuListHammer = new Hammer(topMenuList.get(0));
      topMenuDropdownHammer.on('tap', () => {
        topMenuList.toggleClass('topMenu__list--expanded');
        //Click event fires before tap, so we need to catch and prevent it as the first step
        topMenuList.on('click', e => { e.preventDefault(); });
        //Handles tap and double-tap
        topMenuListHammer.on('tap', e => {
          const $target = $(e.target);
          const $parentalItem = $target.parents('li').first();
          if ($target.get(0).nodeName.toLowerCase() === 'a' && $target.attr('href') === '#') {
            e.preventDefault();
          } else if ($target.get(0).nodeName.toLowerCase() === 'a' && $target.attr('href') !== '#') {
            window.location = $(e.target).prop('href');
          } else if ($target.get(0).nodeName.toLowerCase() === 'i') {
            $parentalItem.toggleClass('topMenu__item--expanded');
            e.srcEvent.stopPropagation();
          }
        });
      });
    }
  }
}

$(function() {
  new Kyokushin();
});
