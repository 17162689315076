import Quill from 'quill';
import Cookies from 'js-cookie';

export default class News {

  constructor() {
    this.newsAdd();
    this.countLinksVisits();
  }

  newsAdd() {
    if ($('#newsBody').length) {
      const quill = new Quill('#newsBody', {
        theme: 'snow',
        modules: {
          toolbar: [
            [
              { 'header': [1, 2, 3, 4, 5, 6, false] },
              'bold',
              'italic',
              'underline',
              'strike',
              { 'script': 'sub' },
              { 'script': 'super' },
              'blockquote',
              { 'color': [] },
              { 'background': [] },
              'clean'
            ],

            [{'align': []}],

            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

            ['link', 'image', 'video'],
          ]
        }
      });
      quill.on('text-change', () => {
        $('[name="form[News][article]"]').val(quill.root.innerHTML);
      });
    }
  }

  countLinksVisits() {
    $('.newsLinks__item').on('click', 'a', e => {
      const linkId = e.currentTarget.dataset.id;
      if (Cookies.get(`linkId:${linkId}`)) {
        return;
      }
      $.post({
        url: `/news/increase-link-visits/id/${linkId}`,
        dataType: 'json',
      })
        .then(({ views_count }) => {
          Cookies.set(`linkId:${linkId}`, true, { expires: 365 });
          $(e.delegateTarget).find('.viewsCount').text(views_count);
        });
    });
  }

}
