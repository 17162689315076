import Cookies from 'js-cookie';

export default class Index {

  constructor() {
    this.countLinksVisits();
  }

  countLinksVisits() {
    $('.wkbLinks__item').on('click', 'a', e => {
      const linkId = e.currentTarget.dataset.id;
      if (Cookies.get(`linkId:${linkId}`)) {
        return;
      }
      $.post({
        url: `/index/increase-link-visits/id/${linkId}`,
        dataType: 'json',
      })
        .then(({ views_count }) => {
          Cookies.set(`linkId:${linkId}`, true);
          $(e.delegateTarget).find('.viewsCount').text(views_count);
        });
    });
  }

}
