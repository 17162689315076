import { GOOGLE_API_KEY } from './constants';

export default class Dojos {
    constructor() {
        this.dojosMap();
    }

    dojosMap() {
        const dojoIndex = $('#dojoIndex');
        const dojoShow = $('#dojoShow');
        const dojosMap = $('#dojosMap');
        const mapsApi = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&language=ru`;
        if (dojosMap.length) {
            $.getScript(mapsApi)
             .then(() => {
                 if (dojoIndex.length) {
                     return this.displayDojosListOnMap(dojoIndex);
                 }
                 if (dojoShow.length) {
                     return this.displayDojoDirectionsOnMap(dojoShow);
                 }
             });
        }
    }

    displayDojosListOnMap(dojoIndex) {
        let map;
        const mapCanvas = $('#mapCanvas');
        const centerCoords = { x: 46.6317460, y: 32.6228330 }; // 8 Morskaya Street
        const dojosList = dojoIndex.find('.contentList__item')
                                   .map((index, element) => {
                                       const elData = $(element).data();
                                       $(element).find('.centerOnMap').on('click', e => centerOnMap(e, elData.coords));
                                       return elData;
                                   }).get();
        function initialize() {
            const mapOptions = {
                center: new google.maps.LatLng(centerCoords.x, centerCoords.y),
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                controlSize: 22,
            };
            map = new google.maps.Map(mapCanvas.get(0), mapOptions);
            setMarkers(map, dojosList);
        }
        function setMarkers(map, locations) {
            //Determines area to show markers on
            const latlngbounds = new google.maps.LatLngBounds();

            locations.forEach(location => {
                const myLatLng = new google.maps.LatLng(...location.coords.split(','));
                //Adds marker coords into area
                latlngbounds.extend(myLatLng);
                new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    animation: google.maps.Animation.DROP,
                    title: location.title,
                });
            });
            //Centers and zooms map
            map.setCenter(latlngbounds.getCenter(), map.fitBounds(latlngbounds));
        }
        function centerOnMap(e, coords) {
            e.preventDefault();
            map.setCenter(new google.maps.LatLng(...coords.split(',')));
            map.setZoom(16);
        }
        initialize();
    }

    displayDojoDirectionsOnMap(dojoShow) {
        const places = [{ coords: dojoShow.data('coords') }];
        const directionsService = new google.maps.DirectionsService();
        const mapCanvas = $('#mapCanvas');
        let directionsDisplay;
        function initialize() {
            directionsDisplay = new google.maps.DirectionsRenderer();
            const mapOptions = {
                center: new google.maps.LatLng(...places[0].coords.split(',')),
                zoom: 5,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                controlSize: 22,
            };
            const map = new google.maps.Map(mapCanvas.get(0), mapOptions);
            setMarkers(map, places);
            directionsDisplay.setMap(map);
            // Means that we successfully retrieved user's location
            if (places[1]) {
                calcRoute();
            }
        }
        function setMarkers(map, locations) {
            //Determines area to show markers on
            const latlngbounds = new google.maps.LatLngBounds();

            locations.forEach(location => {
                const myLatLng = new google.maps.LatLng(...location.coords.split(','));
                //Adds marker coords into area
                latlngbounds.extend(myLatLng);
                new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    animation: google.maps.Animation.DROP,
                    title: location.title,
                });
            });
            //Centers and zooms map
            map.setCenter(latlngbounds.getCenter(), map.fitBounds(latlngbounds));
        }
        function getUserGeolocation() {
            //console.log('getUserGeolocation_1');
            if (navigator.geolocation) {
                //console.log('getUserGeolocation_2');
                navigator.geolocation.getCurrentPosition(position => {
                    //console.log(position);
                    places[1] = ['Предположительно Ваше местоположение', position.coords.latitude, position.coords.longitude];
                    initialize();
                }, error => {
                    console.log(error);
                    initialize();
                });
            } else {
                //console.log('getUserGeolocation_3');
                initialize();
            }
        }
        // TODO: Doesn't work since protocol is not https :(, fix it!
        function calcRoute() {
            const request = {
                origin: new google.maps.LatLng(places[0][1], places[0][2]),
                destination: new google.maps.LatLng(places[1][1], places[1][2]),
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };
            directionsService.route(request, function(response, status) {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
        }
        getUserGeolocation();
    }
}
