import _ from 'lodash';
import photoTemplate from './templates/administer/photo.html';
import galleryPreviewList from './templates/administer/galleryPreviewList.html';
import videoTemplate from './templates/administer/video.html';
import addNewsLink from './templates/administer/addNewsLink.html';
import addAnnouncementLink from './templates/administer/addAnnouncementLink.html';
import addWKBLink from './templates/administer/addWKBLink.html';
import { YOUTUBE_API_KEY } from '../application/constants';

export default class Administer {

  constructor() {
    this.galleryEdit();
    this.photosUpload();
    this.videosUpload();
    this.newsLinkAdd();
    this.newsLinkRemove();
    this.announcementLinkAdd();
    this.announcementLinkRemove();
    this.wkbLinkAdd();
    this.wkbLinkRemove();
  }

  galleryEdit() {
    //Change gallery preview on admin pages
    $('#ChangeImage').on('click', e => {
      const gid = $(e.target).data('gid');
      const loadNextMedia = (gid, page = 1, totalItems) => {
        const limit = 9;
        const offset = (page - 1) * limit;

        $.ajax({
          url: '/gallery/load-media',
          dataType: 'json',
          data: { gid, limit, offset }
        }).then(response => {

          if (response.media.length) {
            $.colorbox({
              html: _.template(galleryPreviewList)({ pages: Math.ceil(totalItems / 9), page, media: response.media }),
              innerWidth: 540,
              innerHeight: 660,
              close: 'X',
              onComplete: () => {
                $('#ChangeThumbnailContainer').on('click', '.admin-preview-item', e => {
                  const image = $(e.currentTarget).find('img');
                  const thumbnail = image.prop('src');
                  const preview = image.data('file');

                  $('#galleryThumbnail').attr('src', thumbnail);
                  $('input[name="form[Gallery][thumbnail]"]').val(preview);
                  $.colorbox.close();
                  e.preventDefault();
                });

                $('#ChangeThumbnailContainer').on('click', 'ul.dialog-pager li a', e => {
                  loadNextMedia(gid, parseInt($(e.target).text()), totalItems);
                  e.preventDefault();
                });
              }
            });
          } else {
            $.colorbox({
              html: 'Фотографий для этой галереи нет :(',
              innerWidth: 540,
              onComplete: function() {}
            });
          }

        });
      };

      loadNextMedia(gid, 1, parseInt($('input[name="form[Gallery][media_count]"]').val()));

      e.preventDefault();
    });
  }

  photosUpload() {
    $('#administerPhotosUpload').on('change', '[name="photos[]"]', e => {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = e => {
          $('.photosList').append(_.template(photoTemplate)({ file, src: e.target.result }));
        };
        reader.readAsDataURL(file);
      }
    });
  }

  videosUpload() {
    $('#administerVideosUpload').on('blur', '[name="form[Video][url]"]', e => {
      const videoId = Administer._extractVideoId($(e.currentTarget).val());
      if (videoId) {
        $.get(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`)
          .then(data => {
            $('#uploadedVideosContainer').html(_.template(videoTemplate)({
              id: data.items[0].id,
              title: data.items[0].snippet.title,
              description: data.items[0].snippet.description,
              thumbnail: data.items[0].snippet.thumbnails.medium.url
            }));
          });
      }
    });
  }

  newsLinkAdd() {
    $('#newsAdd, #newsEdit').on('click', '.addLink', e => {
      e.preventDefault();
      $(e.currentTarget).before(_.template(addNewsLink)());
    });
  }

  newsLinkRemove() {
    $('#newsAdd, #newsEdit').on('click', '.removeLink', e => {
      e.preventDefault();
      $(e.currentTarget).parent().remove();
    });
  }

  announcementLinkAdd() {
    $('#announcementAdd, #announcementEdit').on('click', '.addLink', e => {
      e.preventDefault();
      $(e.currentTarget).before(_.template(addAnnouncementLink)());
    });
  }

  announcementLinkRemove() {
    $('#announcementAdd, #announcementEdit').on('click', '.removeLink', e => {
      e.preventDefault();
      $(e.currentTarget).parent().remove();
    });
  }

  wkbLinkAdd() {
    $('#editWKBDownloadsList').on('click', '.addLink', e => {
      e.preventDefault();
      $(e.currentTarget).before(_.template(addWKBLink)());
    });
  }

  wkbLinkRemove() {
    $('#editWKBDownloadsList').on('click', '.removeLink', e => {
      e.preventDefault();
      $(e.currentTarget).parent().remove();
    });
  }

  static _extractVideoId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  }
}
