export default class Blocks {
    constructor() {
        this.tabbedBlocks();
    }

    tabbedBlocks() {
        const tabbedBlocks = $('.tabbedBlock');
        if (tabbedBlocks.length) {
            tabbedBlocks.on('click', '.tabControl', e => {
                e.preventDefault();
                const block = $(e.delegateTarget);
                const control = $(e.currentTarget);
                block.find('.tabControl').removeClass('active');
                control.addClass('active');
                block.find('.tabData').hide();
                block.find(`.tabData:eq(${control.index()})`).show();
            });
        }
    }
}
