import _ from 'lodash';

export default class Users {
  constructor () {
    this.commonLoginRegister();
  }

  commonLoginRegister () {
    $('.commonLoginRegister__mobileHeader').on('click', 'a', e => {
      const blocksWrapper = $('.commonLoginRegister__top');
      const blocksHandler = $('.commonLoginRegister__mobileHeader');
      const currentHandler = $(e.currentTarget).parent();
      e.preventDefault();
      blocksHandler.find('li').removeClass('active');
      currentHandler.addClass('active');
      blocksWrapper.find('.commonLoginRegister__top__block').hide();
      blocksWrapper.find('.commonLoginRegister__top__block').eq(currentHandler.index()).show();
    });
  }
}
