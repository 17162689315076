import _ from 'lodash';
import videojs from 'video.js';
import kataTemplate from '../templates/pages/kata.html';

export default class Pages {
  constructor() {
    this.basicTechnique();
    this.branches();
    this.plans();
  }

  basicTechnique() {
    $('.player').on('click', e => {
      const elem = $(e.currentTarget);
      $.colorbox({
        html: _.template(kataTemplate)({
          title: elem.prop('title'),
          src: elem.prop('href').replace(/(^.*)\..*$/, '$1')
        }),
        innerHeight: 500,
        innerWidth: 550,
        closeButton: true,
        close: '&#10005',
        onComplete() {
          videojs($('.video-js').get(0));
          $.colorbox.resize();
        }
      });
      e.preventDefault();
    });
  }

  branches() {
    const pageBranches = $('#pageBranches');
    if (pageBranches.length) {
      let branchInfo = $('#branchInfo');
      const mapAreas = pageBranches.find('area');
      const regions = pageBranches.find('.regionsWrapper__region');
      const cities = pageBranches.find('.citiesWrapper__city');
      const loadPage = targetUrl => {
        branchInfo.find('.branchInfo__loader').show();
        return $.get(targetUrl)
                .then(response => {
                  branchInfo.replaceWith(response);
                  branchInfo = $('#branchInfo');
                });
      };
      const highlightArea = areaId => {
        cities.removeClass('citiesWrapper__city--active');
        regions.removeClass('regionsWrapper__region--active');
        cities.filter(`[data-id="${areaId}"]`).addClass('citiesWrapper__city--active');
        regions.filter(`[data-id="${areaId}"]`).addClass('regionsWrapper__region--active');
      };
      const handleEnter = e => highlightArea($(e.target).data('id'));
      const handleClick = e => {
        e.preventDefault();
        let targetUrl = $(e.target).prop('href');
        let areaId = $(e.target).data('id');
        loadPage(targetUrl).then(() => {
          history.pushState({ areaId }, '', targetUrl);
        });
      };
      // Binds handlers to events
      mapAreas
        .hover(_.throttle(handleEnter, 100))
        .click(handleClick);

      window.onpopstate = (e) => {
        loadPage(document.location)
          .then(() => highlightArea(e.state && e.state.areaId || 22));
      };
    }
  }

  plans() {
    const pagePlans = $('#pagePlans');
    if (pagePlans) {
      const printButton = pagePlans.find('.actionsList__print');
      printButton.on('click', e => {
        e.preventDefault();
        window.print();
      });
    }
  }
}
