/**
 * Simple Carousel module written by @l1
 * requires jQuery
 * TODO: unify it
 */
export default class SimpleCarousel {
    
    constructor(carouselElement, options = { visibleItemsCount: 4, firstVisibleItemPosition: 0 }) {
        this.listElement = carouselElement.find('.carousel__list');
        this.prevButton = carouselElement.find('.carousel__prev');
        this.nextButton = carouselElement.find('.carousel__next');
        this.itemsList = carouselElement.find('.carousel__item');
        this.itemWidth = this.itemsList.outerWidth(true);
        this.totalItemsCount = this.itemsList.length;
        this.visibleItemsCount = options.visibleItemsCount;
        this.firstVisibleItemPosition = options.firstVisibleItemPosition;
        this.isScrolling = false;

        this.listElement.css('width', this.itemWidth * this.itemsList.length);
        this.prevButton.on('click', this.scrollPrev.bind(this));
        this.nextButton.on('click', this.scrollNext.bind(this));
        if (this.visibleItemsCount >= this.totalItemsCount) {
            this.nextButton.addClass('carousel__next--disabled');
        }
    }

    scrollPrev(e) {
        e.preventDefault();
        if (this.isScrolling || this.prevButton.hasClass('carousel__prev--disabled')) {
            return;
        }
        const currentOffset = parseInt(this.listElement.css('left').replace('px', ''));
        const newOffset = currentOffset + this.itemWidth;
        this.isScrolling = true;
        if (this.firstVisibleItemPosition !== 0) {
            this.listElement.animate({ left: newOffset }, () => {
                this.firstVisibleItemPosition--;
                this.isScrolling = false;
                if (this.firstVisibleItemPosition === 0) {
                    this.prevButton.addClass('carousel__prev--disabled');
                }
            });
            this.nextButton.removeClass('carousel__next--disabled');
        }
    }

    scrollNext(e) {
        e.preventDefault();
        if (this.isScrolling || this.nextButton.hasClass('carousel__next--disabled')) {
            return;
        }
        const currentOffset = parseInt(this.listElement.css('left').replace('px', ''));
        const newOffset = currentOffset - this.itemWidth;
        const isNotAtTheEnd = this.firstVisibleItemPosition + this.visibleItemsCount !== this.totalItemsCount;
        const isBigEnough = this.visibleItemsCount < this.totalItemsCount;
        this.isScrolling = true;
        if (isNotAtTheEnd && isBigEnough) {
            this.listElement.animate({ left: newOffset }, () => {
                this.firstVisibleItemPosition++;
                this.isScrolling = false;
                if (this.firstVisibleItemPosition + this.visibleItemsCount === this.totalItemsCount) {
                    this.nextButton.addClass('carousel__next--disabled');
                }
            });
            this.prevButton.removeClass('carousel__prev--disabled');
        }
    }
}
