import SimpleCarousel from './libs/simpleCarousel';

export default class Galleries {
    constructor() {
        this.showPhotoGallery();
        this.showPhotoItem();
        this.showVideoItem();
    }

    showPhotoGallery() {
    }

    showPhotoItem() {
        const carouselElement = $('.galleryShowPhotoItem__carousel');
        if (carouselElement) {
            new SimpleCarousel(carouselElement);
        }
    }

    showVideoItem() {
        const carouselElement = $('.galleryShowVideoItem__carousel');
        if (carouselElement) {
            new SimpleCarousel(carouselElement);
        }
    }
}
